.footer {
  text-align: center;
  background: #e0e0e0;
}
.footer .footer-text a {
  text-decoration: none;
  background: #c9ffe5;
  border-radius: 5px;
  padding: 2px;
  color: black;
}
.footer-text {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}
